var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _vm.isMultipleSelect && _vm.tasksCopy.length > 0
            ? _c(
                "b-form-checkbox",
                {
                  attrs: { value: true, "unchecked-value": false },
                  on: { change: _vm.setIsSelectAll },
                  model: {
                    value: _vm.isSelectAll,
                    callback: function ($$v) {
                      _vm.isSelectAll = $$v
                    },
                    expression: "isSelectAll",
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(1, "Select All")) + "\n\t\t"
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "perfect-scrollbar",
        { style: { height: `${_vm.$screen.height - 250}px` } },
        [
          _vm.tasksCopy.length === 0
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2, "No task found")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
          _vm._l(_vm.tasksCopy, function (task, index) {
            return _c("div", { key: index }, [
              _vm.isMultipleSelect
                ? _c(
                    "div",
                    {
                      staticClass:
                        "list-group-item flex-column align-items-start list-group-item-action mt-2",
                      staticStyle: {
                        "border-top": "1px solid rgba(0, 0, 0, 0.125)",
                        cursor: "pointer",
                        "z-index": "0",
                      },
                      style: {
                        backgroundColor: task.isChecked ? "#f3f4f5" : "",
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    value: true,
                                    "unchecked-value": false,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChangeCheckTask(
                                        $event,
                                        task
                                      )
                                    },
                                  },
                                  model: {
                                    value: task.isChecked,
                                    callback: function ($$v) {
                                      _vm.$set(task, "isChecked", $$v)
                                    },
                                    expression: "task.isChecked",
                                  },
                                },
                                [_c("strong", [_vm._v(_vm._s(task.name))])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "list-group-item flex-column align-items-start list-group-item-action mt-2",
                      staticStyle: {
                        "border-top": "1px solid rgba(0, 0, 0, 0.125)",
                        cursor: "pointer",
                        "z-index": "0",
                      },
                      style: {
                        backgroundColor:
                          task === _vm.taskSelected ? "#f3f4f5" : "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickTask(task)
                        },
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("strong", [_vm._v(_vm._s(task.name))]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }