var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _c(
      "fieldset",
      { staticClass: "scheduler-border" },
      [
        _c("legend", { staticClass: "scheduler-border" }, [
          _vm._v(_vm._s(_vm.FormMSG(1, "Users"))),
        ]),
        _c(
          "b-form-group",
          { attrs: { label: "Search", horizontal: "", "label-cols": "3" } },
          [
            _c("b-form-input", {
              attrs: { type: "text" },
              on: { input: _vm.handleInputSearch },
            }),
          ],
          1
        ),
        _vm.activeCheckbox && !_vm.isSingleSelect
          ? _c(
              "div",
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      id: "checkbox-user-select-all",
                      value: true,
                      "unchecked-value": false,
                    },
                    model: {
                      value: _vm.isSelectAll,
                      callback: function ($$v) {
                        _vm.isSelectAll = $$v
                      },
                      expression: "isSelectAll",
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(2, "Select All")) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "perfect-scrollbar",
          { style: { height: `${(_vm.$screen.height - 385) / 2}px` } },
          _vm._l(_vm.listUsers, function (user, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass:
                  "list-group-item flex-column align-items-start list-group-item-action mt-2",
                staticStyle: {
                  "border-top": "1px solid rgba(0, 0, 0, 0.125)",
                  cursor: "pointer",
                  "z-index": "0",
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _vm.activeCheckbox && !_vm.isSingleSelect
                      ? _c(
                          "b-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("b-form-checkbox", {
                              attrs: {
                                name: `checkbox-${index}`,
                                value: true,
                                "unchecked-value": false,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.addAUserSelected($event, user)
                                },
                              },
                              model: {
                                value: user.isAssign,
                                callback: function ($$v) {
                                  _vm.$set(user, "isAssign", $$v)
                                },
                                expression: "user.isAssign",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeCheckbox && _vm.isSingleSelect
                      ? _c(
                          "b-col",
                          { attrs: { cols: "2" } },
                          [
                            _c("b-form-radio", {
                              attrs: {
                                name: "list-users-radio",
                                value: user,
                                "aria-describedby": "ariaDescribedby",
                                disabled: _vm.disableUsers,
                              },
                              on: { input: _vm.handleInputListUsersRadio },
                              model: {
                                value: _vm.singleSelected,
                                callback: function ($$v) {
                                  _vm.singleSelected = $$v
                                },
                                expression: "singleSelected",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("b-col", { attrs: { cols: "3" } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "lazy-container",
                              rawName: "v-lazy-container",
                              value: { selector: "img" },
                              expression: "{ selector: 'img' }",
                            },
                          ],
                        },
                        [
                          user.picture !== ""
                            ? _c("img", {
                                staticClass: "img-circle-custom",
                                attrs: {
                                  "data-src": _vm.pathPicture(user.picture),
                                  "data-error": "img/brand/avatar.jpg",
                                  "data-loading": "img/brand/tenor.gif",
                                  alt: user.picture,
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "not-img",
                                  style: { backgroundColor: user.color },
                                },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.getInitial(user.name))),
                                  ]),
                                ]
                              ),
                        ]
                      ),
                    ]),
                    _c(
                      "b-col",
                      { attrs: { cols: _vm.activeCheckbox ? 7 : 9 } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(user.name + " " + user.firstName) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }