var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(1, "List of tasks")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(2, "List users and teams")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(35, "For dates")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "2" } }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticStyle: { "border-right": "1px solid rgba(0, 0, 0, 0.125)" },
              attrs: { cols: "4" },
            },
            [
              _c("list-task", {
                ref: "assignmentsListTask",
                attrs: { tasks: _vm.tasks },
                on: { "list-task:selected": _vm.handleListTaskSelected },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticStyle: { "border-right": "1px solid rgba(0, 0, 0, 0.125)" },
              attrs: { cols: "3" },
            },
            [
              _c("list-users", {
                key: "assignments-list-users",
                attrs: { users: _vm.users, task: _vm.taskSelected },
                on: { "list-users:selected": _vm.handleListUsersSelected },
              }),
              _c("list-teams", {
                key: "assignments-list-teams",
                attrs: { teams: _vm.teams, task: _vm.taskSelected },
                on: { "list-teams:selected": _vm.handleListTeamsSelected },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c("list-plannings", {
                attrs: { "list-plannings": _vm.listPlannings },
                on: {
                  "list-planning:selected": _vm.handleListPlanningSelected,
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "success",
                    block: "",
                    disabled: _vm.enableBtnAssign,
                  },
                  on: { click: _vm.handleClickBtnAssign },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.FormMSG(4, "Assign")) + "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }