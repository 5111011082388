var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(1, "List of tasks")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(2, "List users and teams")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(43, "Replace by")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "2" } }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticStyle: { "border-right": "1px solid rgba(0, 0, 0, 0.125)" },
              attrs: { cols: "4" },
            },
            [
              _c("list-task", {
                key: "substitution_list_tasks",
                ref: "substitutionListTasks",
                attrs: { tasks: _vm.tasks, "is-multiple-select": "" },
                on: { "list-task:selected": _vm.handleListTaskSelected },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticStyle: { "border-right": "1px solid rgba(0, 0, 0, 0.125)" },
              attrs: { cols: "3" },
            },
            [
              _c("list-users", {
                key: "substitution-list-users",
                ref: "substitutionListUsers",
                attrs: {
                  users: _vm.users,
                  "array-task": _vm.taskSelected,
                  "is-single-select": "",
                  "disable-users": _vm.disableUserAndTeam,
                },
                on: { "list-users:selected": _vm.handleListUsersSelected },
              }),
              _c("list-teams", {
                key: "substitution-list-teams",
                ref: "substitutionListTeams",
                attrs: {
                  teams: _vm.teams,
                  "array-task": _vm.taskSelected,
                  "is-single-select": "",
                  "disable-teams": _vm.disableUserAndTeam,
                },
                on: { "list-teams:selected": _vm.handleListTeamsSelected },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _vm.newUserSelected
                ? _c(
                    "div",
                    {
                      staticClass:
                        "list-group-item flex-column align-items-start list-group-item-action mt-2",
                      staticStyle: {
                        "border-top": "1px solid rgba(0, 0, 0, 0.125)",
                        cursor: "pointer",
                        "z-index": "0",
                      },
                    },
                    [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "font-weight": "bolder",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.FormMSG(45, "Replaced by")) + " :"
                            ),
                          ]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(_vm.newUserSelected.name) +
                            "\n\t\t\t\t\t" +
                            _vm._s(_vm.newUserSelected.firstName) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "6", offset: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "float-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { variant: "primary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickEdit("user")
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "pen-fill" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger", size: "sm" },
                                    on: {
                                      click: _vm.handleClickDeleteUserReplace,
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "trash-fill" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.newTeamSelected
                ? _c(
                    "div",
                    {
                      staticClass:
                        "list-group-item flex-column align-items-start list-group-item-action mt-2",
                      staticStyle: {
                        "border-top": "1px solid rgba(0, 0, 0, 0.125)",
                        cursor: "pointer",
                        "z-index": "0",
                      },
                    },
                    [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "font-weight": "bolder",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.FormMSG(45, "Replaced by")) + " :"
                            ),
                          ]
                        ),
                        _vm._v(
                          " " + _vm._s(_vm.newTeamSelected.name) + "\n\t\t\t\t"
                        ),
                      ]),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "6", offset: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "float-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { variant: "primary", size: "sm" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickEdit("team")
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "pen-fill" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger", size: "sm" },
                                    on: {
                                      click: _vm.handleClickDeleteTeamReplace,
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "trash-fill" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-alert", { attrs: { variant: "info", show: "" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                4,
                                "Select the date range in which the user will be replaced"
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.FormMSG(5, "Start date")) + " :"),
                      ]),
                      _c("v-date-picker", {
                        attrs: {
                          masks: _vm.masks,
                          locale: _vm.lang,
                          "max-date": _vm.range.end,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ inputValue, inputEvents }) {
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: _vm.FormMSG(
                                          5,
                                          "Start date"
                                        ),
                                      },
                                      domProps: { value: inputValue },
                                    },
                                    inputEvents
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.range.start,
                          callback: function ($$v) {
                            _vm.$set(_vm.range, "start", $$v)
                          },
                          expression: "range.start",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.FormMSG(6, "End date")) + " :"),
                      ]),
                      _c("v-date-picker", {
                        attrs: {
                          mode: "date",
                          masks: _vm.masks,
                          locale: _vm.lang,
                          "min-date": _vm.range.start,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ inputValue, inputEvents }) {
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: _vm.FormMSG(6, "End date"),
                                      },
                                      domProps: { value: inputValue },
                                    },
                                    inputEvents
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.range.end,
                          callback: function ($$v) {
                            _vm.$set(_vm.range, "end", $$v)
                          },
                          expression: "range.end",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("replace-modal", {
                attrs: {
                  open: _vm.showReplaceModal,
                  "is-for-user": _vm.forUserReplaceModal,
                  users: _vm.users,
                  teams: _vm.teams,
                  "user-selected": _vm.usersSelected,
                  "team-selected": _vm.teamsSelected,
                  "task-selected": _vm.taskSelected,
                  "data-user-selected": _vm.newUserSelected,
                  "data-team-selected": _vm.newTeamSelected,
                },
                on: {
                  "replace-modal:close": _vm.handleReplaceModalClose,
                  "replace-modal:new-user-selected":
                    _vm.handleReplaceModalNewUserSelected,
                  "replace-modal:new-team-selected":
                    _vm.handleReplaceModalNewTeamSelected,
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "success",
                    block: "",
                    disabled: _vm.enableBtnAssign,
                  },
                  on: { click: _vm.handleClickBtnSubstitut },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(7, "Replace")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }