<template>
	<form>
		<fieldset class="scheduler-border">
			<legend class="scheduler-border">{{ FormMSG(1, 'Teams') }}</legend>
			<b-form-group label="Search" horizontal label-cols="3">
				<b-form-input type="text" @input="handleInputSearch" />
			</b-form-group>
			<div v-show="activeCheckbox && !isSingleSelect" style="transition: display 0.5s">
				<b-form-checkbox v-model="isSelectAll" :value="true" :unchecked-value="false">
					{{ FormMSG(2, 'Select All') }}
				</b-form-checkbox>
			</div>
			<perfect-scrollbar :style="{ height: `${($screen.height - 385) / 2}px` }">
				<div
					v-for="(team, index) in listTeams"
					:key="index"
					class="list-group-item flex-column align-items-start list-group-item-action mt-2"
					style="border-top: 1px solid rgba(0, 0, 0, 0.125); cursor: pointer; z-index: 0"
				>
					<b-row>
						<b-col v-show="activeCheckbox && !isSingleSelect" cols="2">
							<b-form-checkbox
								v-model="team.isAssign"
								:name="`checkbox-team-${index}`"
								:value="true"
								:unchecked-value="false"
								@change="addATeamSelected($event, team)"
							/>
						</b-col>
						<b-col v-if="activeCheckbox && isSingleSelect" cols="2">
							<b-form-radio
								v-model="singleSelected"
								name="list-users-radio"
								:value="team"
								aria-describedby="ariaDescribedby"
								:disabled="disableTeams"
								@input="handleInputListUsersRadio"
							/>
						</b-col>
						<b-col cols="3">
							<div :style="{ backgroundColor: `#${base16ToHexa(team.color)}` }" class="not-img" />
						</b-col>
						<b-col :cols="activeCheckbox ? 7 : 9">
							{{ team.name }}
						</b-col>
					</b-row>
				</div>
			</perfect-scrollbar>
		</fieldset>
	</form>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'ListTeams',

	components: {
		PerfectScrollbar
	},

	mixins: [GlobalMixin, languageMessages],

	props: {
		teams: { type: Array, default: () => [] },
		task: { type: Object, default: null },
		arrayTask: { type: Array, default: () => [] },
		isSingleSelect: { type: Boolean, default: false },
		singleDataSelected: { type: Object, default: null },
		disableTeams: { type: Boolean, default: false }
	},

	data() {
		return {
			listTeams: [],
			isSelectAll: false,
			teamsSelected: [],
			singleSelected: null
		};
	},

	computed: {
		activeCheckbox() {
			if (this.task !== null || this.arrayTask.length > 0) {
				return true;
			} else {
				return false;
			}
		}
	},

	watch: {
		teams: {
			handler(val) {
				val.map((team) => {
					team.isAssign = false;
				});
				this.listTeams = val;
			},
			immediate: true
		},
		task: {
			handler(val) {
				this.teamsSelected = [];
				this.initIsAssignListTeams();
			},
			immediate: true
		},
		isSelectAll: {
			handler(val) {
				const tmpListTeams = this.listTeams;
				this.listTeams = [];
				tmpListTeams.map((lT) => {
					lT.isAssign = val;
				});
				this.teamsSelected = tmpListTeams;
				this.listTeams = tmpListTeams;

				this.emitEvent();
			}
		},
		singleDataSelected: {
			handler(val) {
				this.singleSelected = val;
			},
			immediate: true
		}
	},

	methods: {
		initData() {
			this.isSelectAll = false;
			this.teamsSelected = [];
			this.singleSelected = null;
		},
		handleInputListUsersRadio(payload) {
			this.singleSelected = payload;
			this.teamsSelected = payload;
			this.emitEvent();
		},
		addATeamSelected(payload, team) {
			if (payload === true) {
				this.teamsSelected.push(team);
			} else {
				this.teamsSelected = this.teamsSelected.filter((tS) => tS !== team);
			}

			this.emitEvent();
		},
		initIsAssignListTeams() {
			let tmpListTeams = this.listTeams;
			this.listTeams = [];
			tmpListTeams.map((lT) => {
				lT.isAssign = false;
			});
			this.listTeams = tmpListTeams;
		},
		emitEvent() {
			this.$emit('list-teams:selected', {
				teamsSelected: this.teamsSelected
			});
		},
		handleInputSearch(payload) {
			this.listTeams = this.searchStringInArray(payload, this.teams);
		},
		searchStringInArray(str, strArray) {
			let result = [];
			for (let j = 0; j < strArray.length; j++) {
				let pattern = RegExp(str, 'gi');
				if (strArray[j].name.match(pattern)) result.push(strArray[j]);
			}
			return result;
		}
	}
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />

<style lang="scss">
.not-img {
	display: inline-block;
	border-radius: 26px;
	width: 26px;
	height: 26px;
	color: white;
	position: relative;
	> div {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		display: flex;
	}
}
</style>
