var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(1, "List of tasks")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(2, "List users and teams")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "3" } }, [
            _c(
              "p",
              { staticStyle: { "font-size": "16px", "font-weight": "bolder" } },
              [_vm._v(_vm._s(_vm.FormMSG(45, "For dates")))]
            ),
          ]),
          _c("b-col", { attrs: { cols: "2" } }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticStyle: { "border-right": "1px solid rgba(0, 0, 0, 0.125)" },
              attrs: { cols: "4" },
            },
            [
              _c("list-task", {
                ref: "disassignmentsListTask",
                attrs: { tasks: _vm.tasks, "is-multiple-select": "" },
                on: { "list-task:selected": _vm.handleListTaskSelected },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticStyle: { "border-right": "1px solid rgba(0, 0, 0, 0.125)" },
              attrs: { cols: "3" },
            },
            [
              _c("list-users", {
                key: "disassignment-list-users",
                attrs: { users: _vm.users, "array-task": _vm.taskSelected },
                on: { "list-users:selected": _vm.handleListUsersSelected },
              }),
              _c("list-teams", {
                key: "disassignment-list-teams",
                attrs: { teams: _vm.teams, "array-task": _vm.taskSelected },
                on: { "list-teams:selected": _vm.handleListTeamsSelected },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-alert", { attrs: { variant: "info", show: "" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                4,
                                "Select the date range in which the user will be unassigned"
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.FormMSG(5, "Start date")) + " :"),
                      ]),
                      _c("v-date-picker", {
                        attrs: {
                          masks: _vm.masks,
                          locale: _vm.lang,
                          "max-date": _vm.range.end,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ inputValue, inputEvents }) {
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: _vm.FormMSG(
                                          5,
                                          "Start date"
                                        ),
                                      },
                                      domProps: { value: inputValue },
                                    },
                                    inputEvents
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.range.start,
                          callback: function ($$v) {
                            _vm.$set(_vm.range, "start", $$v)
                          },
                          expression: "range.start",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.FormMSG(6, "End date")) + " :"),
                      ]),
                      _c("v-date-picker", {
                        attrs: {
                          mode: "date",
                          masks: _vm.masks,
                          locale: _vm.lang,
                          "min-date": _vm.range.start,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ inputValue, inputEvents }) {
                              return [
                                _c(
                                  "input",
                                  _vm._g(
                                    {
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder: _vm.FormMSG(6, "End date"),
                                      },
                                      domProps: { value: inputValue },
                                    },
                                    inputEvents
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.range.end,
                          callback: function ($$v) {
                            _vm.$set(_vm.range, "end", $$v)
                          },
                          expression: "range.end",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "success",
                    block: "",
                    disabled: _vm.enableBtnAssign,
                  },
                  on: { click: _vm.handleClickBtnDisassign },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(47, "Deassign")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }