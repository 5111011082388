<template>
	<div>
		<div v-if="listPlannings.length === 0" class="text-center">
			{{ FormMSG(1, 'Please select a task please') }}
		</div>
		<div v-if="listPlannings.length > 0">
			<p>{{ FormMSG(2, 'Search by dates') }} :</p>
			<div style="display: flex">
				<div :style="`width: ${showClearFilter ? '43%' : '49%'}; margin-right: 2%`">
					<v-date-picker v-model="range.start" :masks="masks" :locale="lang" :max-date="range.end" @input="handleInputStartDate">
						<template v-slot="{ inputValue, inputEvents }">
							<input class="form-control" :placeholder="FormMSG(3, 'Start date')" :value="inputValue" v-on="inputEvents" />
						</template>
					</v-date-picker>
				</div>
				<div :style="`width: ${showClearFilter ? '43%' : '49%'}; margin-right: ${showClearFilter ? '2%' : '0'}`">
					<v-date-picker v-model="range.end" mode="date" :masks="masks" :locale="lang" :min-date="range.start" @input="handleInputEndDate">
						<template v-slot="{ inputValue, inputEvents }">
							<input class="form-control" :placeholder="FormMSG(4, 'End date')" :value="inputValue" v-on="inputEvents" />
						</template>
					</v-date-picker>
				</div>
				<div v-if="showClearFilter" style="width: 10%">
					<b-button variant="danger" @click="handleClickClearFilter"> x </b-button>
				</div>
			</div>
			<b-row class="mb-3 mt-3">
				<b-col cols="6">
					<b-form-checkbox id="checkbox-is-select-all" v-model="isSelectAll" :value="true" :unchecked-value="false" @change="setIsSelectAll">
						{{ FormMSG(5, 'Select All') }}
					</b-form-checkbox>
				</b-col>
			</b-row>
		</div>
		<perfect-scrollbar :style="{ height: `${$screen.height - 250}px` }">
			<div>
				<div v-for="(planning, i) in plannings" :key="i">
					<div class="custom__card mt-2" style="cursor: pointer" :style="{ backgroundColor: planning.isChecked ? '#f3f4f5' : '' }">
						<b-form-checkbox
							:key="planning.id"
							v-model="planning.isChecked"
							:value="true"
							:unchecked-value="false"
							@change="handleInputCheckPlanning($event, planning)"
						>
							<strong>{{ planning.desiredStartFormatting }}</strong>
						</b-form-checkbox>
					</div>
				</div>
			</div>
		</perfect-scrollbar>
	</div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'ListPlannings',

	components: {
		PerfectScrollbar,
		'v-date-picker': DatePicker
	},

	mixins: [GlobalMixin, languageMessages],

	props: {
		listPlannings: { type: Array, default: () => [] }
	},

	data() {
		return {
			isSelectAll: false,
			planningsSelected: [],
			plannings: [],
			range: {
				start: null,
				end: null
			},
			today: new Date(),
			masks: {
				input: 'DD MMMM YYYY'
			}
		};
	},

	computed: {
		showClearFilter() {
			return this.range.start || this.range.end;
		}
	},

	watch: {
		listPlannings: {
			handler(val) {
				this.plannings = [];
				this.planningsSelected = [];
				this.plannings = [...val];
				this.isSelectAll = false;
				if (val.length === 0) {
					this.range.start = null;
					this.range.end = null;
				}
			},
			immediate: true
		}
	},

	methods: {
		handleClickClearFilter() {
			this.initIsChecked();
			this.plannings = [...this.listPlannings];
			this.range.start = null;
			this.range.end = null;
			this.planningsSelected = [];
			this.checkIsSelectAll();
			this.emitEvent();
		},
		setIsSelectAll(val) {
			let tmpPlannings = this.plannings;
			this.plannings = [];
			tmpPlannings.map((tP) => {
				tP.isChecked = val;
			});
			if (val === true) {
				this.planningsSelected = tmpPlannings;
			} else {
				this.planningsSelected = [];
			}
			this.plannings = tmpPlannings;
			this.emitEvent();
		},
		handleInputStartDate(payload) {
			const payloadToMoment = new Date(moment(payload).format('YYYY-MM-DD') + 'T00:00:00Z');
			let endDate = null;
			if (this.range.end) {
				endDate = new Date(moment(this.range.end).format('YYYY-MM-DD') + 'T00:00:00Z');
			}
			let plannings = [];
			const tmpListPlannings = [...this.listPlannings];
			tmpListPlannings.map((p) => {
				const desiredStartToTime = p.desiredStart.getTime();
				if (endDate) {
					if (desiredStartToTime >= payloadToMoment.getTime() && desiredStartToTime <= endDate.getTime) {
						p.isChecked = true;
						plannings.push(p);
					}
				} else {
					if (desiredStartToTime >= payloadToMoment.getTime()) {
						p.isChecked = true;
						plannings.push(p);
					}
				}
				return false;
			});
			this.planningsSelected = plannings;
			this.plannings = plannings;
			this.checkIsSelectAll();
			this.emitEvent();
		},
		handleInputEndDate(payload) {
			const payloadToMoment = new Date(moment(payload).format('YYYY-MM-DD') + 'T00:00:00Z');
			let startDate = null;
			if (this.range.start) {
				startDate = new Date(moment(this.range.start).format('YYYY-MM-DD') + 'T00:00:00Z');
			}
			let plannings = [];
			const tmpListPlannings = [...this.listPlannings];
			tmpListPlannings.filter((p) => {
				const desiredStartToTime = p.desiredStart.getTime();
				if (startDate) {
					if (desiredStartToTime <= payloadToMoment.getTime() && desiredStartToTime >= startDate.getTime()) {
						p.isChecked = true;
						plannings.push(p);
					}
				} else {
					if (desiredStartToTime <= payloadToMoment.getTime()) {
						p.isChecked = true;
						plannings.push(p);
					}
				}
				return false;
			});
			this.planningsSelected = plannings;
			this.plannings = plannings;
			this.checkIsSelectAll();
			this.emitEvent();
		},
		handleInputCheckPlanning(payload, planning) {
			if (payload) {
				this.planningsSelected.push(planning);
			} else {
				this.planningsSelected = this.planningsSelected.filter((pS) => pS !== planning);
			}
			this.checkIsSelectAll();
			this.emitEvent();
		},
		checkIsSelectAll() {
			let isSelectAll = true;
			if (this.planningsSelected.length !== this.plannings.length) {
				isSelectAll = false;
			}
			this.isSelectAll = isSelectAll;
		},
		emitEvent() {
			this.$emit('list-planning:selected', {
				plannings: this.planningsSelected
			});
		},
		initIsChecked() {
			this.listPlannings.map((lP) => {
				lP.isChecked = false;
			});
		}
	}
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
