<template>
	<div>
		<b-row>
			<b-col cols="4">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(1, 'List of tasks') }}</p>
			</b-col>
			<b-col cols="3">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(2, 'List users and teams') }}</p>
			</b-col>
			<b-col cols="3">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(45, 'For dates') }}</p>
			</b-col>
			<b-col cols="2" />
		</b-row>
		<b-row>
			<b-col cols="4" style="border-right: 1px solid rgba(0, 0, 0, 0.125)">
				<list-task ref="disassignmentsListTask" :tasks="tasks" is-multiple-select @list-task:selected="handleListTaskSelected" />
			</b-col>
			<b-col cols="3" style="border-right: 1px solid rgba(0, 0, 0, 0.125)">
				<list-users key="disassignment-list-users" :users="users" :array-task="taskSelected" @list-users:selected="handleListUsersSelected" />
				<list-teams key="disassignment-list-teams" :teams="teams" :array-task="taskSelected" @list-teams:selected="handleListTeamsSelected" />
			</b-col>
			<b-col cols="3">
				<b-row>
					<b-col>
						<b-alert variant="info" show>
							{{ FormMSG(4, 'Select the date range in which the user will be unassigned') }}
						</b-alert>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<p>{{ FormMSG(5, 'Start date') }} :</p>
						<v-date-picker v-model="range.start" :masks="masks" :locale="lang" :max-date="range.end">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="form-control" :placeholder="FormMSG(5, 'Start date')" :value="inputValue" v-on="inputEvents" />
							</template>
						</v-date-picker>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col>
						<p>{{ FormMSG(6, 'End date') }} :</p>
						<v-date-picker v-model="range.end" mode="date" :masks="masks" :locale="lang" :min-date="range.start">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="form-control" :placeholder="FormMSG(6, 'End date')" :value="inputValue" v-on="inputEvents" />
							</template>
						</v-date-picker>
					</b-col>
				</b-row>
			</b-col>
			<b-col cols="2">
				<b-button variant="success" block :disabled="enableBtnAssign" @click="handleClickBtnDisassign">
					{{ FormMSG(47, 'Deassign') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { assigneeMultiple } from '@/cruds/task.crud';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import GlobalMixin from '@/mixins/global.mixin';
import ListTeams from '@/components/AssignationMultiple/ListTeams';
import ListUsers from '@/components/AssignationMultiple/ListUsers';
import ListPlannings from '@/components/AssignationMultiple/ListPlannings';
import ListTask from './ListTask';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'Disassignments',

	components: {
		PerfectScrollbar,
		ListTeams,
		ListUsers,
		ListPlannings,
		ListTask,
		'v-date-picker': DatePicker
	},

	mixins: [GlobalMixin, languageMessages],

	props: {
		tasks: { type: Array, default: () => [] },
		users: { type: Array, default: () => [] },
		teams: { type: Array, default: () => [] }
	},

	data() {
		return {
			taskSelected: [],
			usersSelected: [],
			teamsSelected: [],
			isSelectAll: false,
			masks: {
				input: 'DD MMMM YYYY'
			},
			range: {
				start: new Date(),
				end: new Date()
			},
			today: new Date()
		};
	},

	computed: {
		enableBtnAssign() {
			if (this.taskSelected !== null && (this.usersSelected.length > 0 || this.teamsSelected.length > 0)) {
				return false;
			}

			return true;
		}
	},

	methods: {
		async handleClickBtnDisassign() {
			const action = async () => {
				const inputForUser = await this.prepareDataToSend('user');
				const inputForTeam = await this.prepareDataToSend('team');
				if (inputForUser.length > 0) {
					await assigneeMultiple(null, inputForUser);
				}
				if (inputForTeam.length > 0) {
					await assigneeMultiple(null, inputForTeam);
				}

				this.initData();

				await this.createToastForMobile('Success', this.FormMSG(8, 'Assignements removed successfully'), '', 'success', true);
			};

			await this.confirmModal(action, this.FormMSG(9, 'Are you sure ?'));
		},
		async handleListTeamsSelected(payload) {
			this.teamsSelected = payload.teamsSelected;
		},
		async handleListUsersSelected(payload) {
			this.usersSelected = payload.usersSelected;
		},
		async handleListTaskSelected(task) {
			this.taskSelected = task;
		},
		initData() {
			this.$refs.disassignmentsListTask.initData();
			this.taskSelected = [];
			this.usersSelected = [];
			this.teamsSelected = [];
		},
		prepareDataToSend(type) {
			let dataToSend = [];
			const start = moment(this.range.start).format('YYYY-MM-DD') + 'T00:00:00Z';
			if (type === 'user') {
				if (this.usersSelected.length > 0) {
					for (let i = 0; i < this.taskSelected.length; i++) {
						const task = this.taskSelected[i];
						for (let j = 0; j < this.usersSelected.length; j++) {
							const user = this.usersSelected[j];
							dataToSend.push({
								taskId: parseInt(task.id, 10),
								userId: parseInt(user.id, 10),
								startDate: this.range.start ? moment(this.range.start).format('YYYY-MM-DD') + 'T00:00:00Z' : '',
								endDate: this.range.end ? moment(this.range.end).format('YYYY-MM-DD') + 'T23:59:59Z' : ''
							});
						}
					}
				}
			} else {
				if (this.teamsSelected.length > 0) {
					for (let i = 0; i < this.taskSelected.length; i++) {
						const task = this.taskSelected[i];
						for (let j = 0; j < this.teamsSelected.length; j++) {
							const team = this.teamsSelected[j];
							dataToSend.push({
								taskId: parseInt(task.id, 10),
								teamId: parseInt(team.id, 10),
								startDate: this.range.start ? moment(this.range.start).format('YYYY-MM-DD') + 'T00:00:00Z' : '',
								endDate: this.range.end ? moment(this.range.end).format('YYYY-MM-DD') + 'T23:59:59Z' : ''
							});
						}
					}
				}
			}

			return dataToSend;
		}
	}
};
</script>

<style scoped>
.alert-info {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
}
</style>
