<template>
	<div>
		<b-row>
			<b-col cols="4">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(1, 'List of tasks') }}</p>
			</b-col>
			<b-col cols="3">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(2, 'List users and teams') }}</p>
			</b-col>
			<b-col cols="3">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(43, 'Replace by') }}</p>
			</b-col>
			<b-col cols="2" />
		</b-row>
		<b-row>
			<b-col cols="4" style="border-right: 1px solid rgba(0, 0, 0, 0.125)">
				<list-task
					ref="substitutionListTasks"
					key="substitution_list_tasks"
					:tasks="tasks"
					is-multiple-select
					@list-task:selected="handleListTaskSelected"
				/>
			</b-col>
			<b-col cols="3" style="border-right: 1px solid rgba(0, 0, 0, 0.125)">
				<list-users
					ref="substitutionListUsers"
					key="substitution-list-users"
					:users="users"
					:array-task="taskSelected"
					is-single-select
					:disable-users="disableUserAndTeam"
					@list-users:selected="handleListUsersSelected"
				/>
				<list-teams
					ref="substitutionListTeams"
					key="substitution-list-teams"
					:teams="teams"
					:array-task="taskSelected"
					is-single-select
					:disable-teams="disableUserAndTeam"
					@list-teams:selected="handleListTeamsSelected"
				/>
			</b-col>
			<b-col cols="3">
				<div
					v-if="newUserSelected"
					class="list-group-item flex-column align-items-start list-group-item-action mt-2"
					style="border-top: 1px solid rgba(0, 0, 0, 0.125); cursor: pointer; z-index: 0"
				>
					<p>
						<span style="font-size: 12px; font-weight: bolder">{{ FormMSG(45, 'Replaced by') }} :</span> {{ newUserSelected.name }}
						{{ newUserSelected.firstName }}
					</p>
					<b-row>
						<b-col cols="6" offset="6">
							<div class="float-right">
								<b-button variant="primary" size="sm" class="mr-2" @click="handleClickEdit('user')">
									<b-icon icon="pen-fill" />
								</b-button>
								<b-button variant="danger" size="sm" @click="handleClickDeleteUserReplace">
									<b-icon icon="trash-fill" />
								</b-button>
							</div>
						</b-col>
					</b-row>
				</div>
				<div
					v-if="newTeamSelected"
					class="list-group-item flex-column align-items-start list-group-item-action mt-2"
					style="border-top: 1px solid rgba(0, 0, 0, 0.125); cursor: pointer; z-index: 0"
				>
					<p>
						<span style="font-size: 12px; font-weight: bolder">{{ FormMSG(45, 'Replaced by') }} :</span> {{ newTeamSelected.name }}
					</p>
					<b-row>
						<b-col cols="6" offset="6">
							<div class="float-right">
								<b-button variant="primary" size="sm" class="mr-2" @click="handleClickEdit('team')">
									<b-icon icon="pen-fill" />
								</b-button>
								<b-button variant="danger" size="sm" @click="handleClickDeleteTeamReplace">
									<b-icon icon="trash-fill" />
								</b-button>
							</div>
						</b-col>
					</b-row>
				</div>
				<b-row class="mt-3">
					<b-col>
						<b-alert variant="info" show>
							{{ FormMSG(4, 'Select the date range in which the user will be replaced') }}
						</b-alert>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col>
						<p>{{ FormMSG(5, 'Start date') }} :</p>
						<v-date-picker v-model="range.start" :masks="masks" :locale="lang" :max-date="range.end">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="form-control" :placeholder="FormMSG(5, 'Start date')" :value="inputValue" v-on="inputEvents" />
							</template>
						</v-date-picker>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col>
						<p>{{ FormMSG(6, 'End date') }} :</p>
						<v-date-picker v-model="range.end" mode="date" :masks="masks" :locale="lang" :min-date="range.start">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="form-control" :placeholder="FormMSG(6, 'End date')" :value="inputValue" v-on="inputEvents" />
							</template>
						</v-date-picker>
					</b-col>
				</b-row>
				<replace-modal
					:open="showReplaceModal"
					:is-for-user="forUserReplaceModal"
					:users="users"
					:teams="teams"
					:user-selected="usersSelected"
					:team-selected="teamsSelected"
					:task-selected="taskSelected"
					:data-user-selected="newUserSelected"
					:data-team-selected="newTeamSelected"
					@replace-modal:close="handleReplaceModalClose"
					@replace-modal:new-user-selected="handleReplaceModalNewUserSelected"
					@replace-modal:new-team-selected="handleReplaceModalNewTeamSelected"
				/>
			</b-col>
			<b-col cols="2">
				<b-button variant="success" block :disabled="enableBtnAssign" @click="handleClickBtnSubstitut">
					{{ FormMSG(7, 'Replace') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { replaceAssignee } from '@/cruds/task.crud';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import GlobalMixin from '@/mixins/global.mixin';
import ListTeams from '@/components/AssignationMultiple/ListTeams';
import ListUsers from '@/components/AssignationMultiple/ListUsers';
import ListPlannings from '@/components/AssignationMultiple/ListPlannings';
import ListTask from './ListTask';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';
import ReplaceModal from './ReplaceModal';

export default {
	name: 'Substitution',

	components: {
		PerfectScrollbar,
		ListTeams,
		ListUsers,
		ListPlannings,
		ListTask,
		'v-date-picker': DatePicker,
		ReplaceModal
	},

	mixins: [GlobalMixin],

	props: {
		tasks: { type: Array, default: () => [] },
		users: { type: Array, default: () => [] },
		teams: { type: Array, default: () => [] }
	},

	data() {
		return {
			taskSelected: [],
			usersSelected: null,
			teamsSelected: null,
			newUserSelected: null,
			newTeamSelected: null,
			isSelectAll: false,
			masks: {
				input: 'DD MMMM YYYY'
			},
			range: {
				start: new Date(),
				end: new Date()
			},
			today: new Date(),
			showReplaceModal: false,
			forUserReplaceModal: false,
			disableUserAndTeam: false
		};
	},

	computed: {
		enableBtnAssign() {
			if (this.taskSelected !== null && (this.usersSelected !== null || this.teamsSelected !== null)) {
				return false;
			}

			return true;
		}
	},

	methods: {
		handleClickEdit(type) {
			this.showReplaceModal = true;
			if (type === 'user') {
				this.forUserReplaceModal = true;
			} else {
				this.forUserReplaceModal = false;
			}
		},
		handleClickDeleteTeamReplace() {
			this.newTeamSelected = null;
			this.disableUserAndTeam = false;
			this.$refs.substitutionListTeams.initData();
		},
		handleClickDeleteUserReplace() {
			this.newUserSelected = null;
			this.disableUserAndTeam = false;
			this.$refs.substitutionListUsers.initData();
		},
		handleReplaceModalNewTeamSelected(payload) {
			this.newTeamSelected = payload;
			this.showReplaceModal = false;
		},
		handleReplaceModalNewUserSelected(payload) {
			this.newUserSelected = payload;
			this.showReplaceModal = false;
		},
		handleReplaceModalClose() {
			this.showReplaceModal = false;
			if (this.forUserReplaceModal && this.newUserSelected === null) {
				this.disableUserAndTeam = false;
				this.$refs.substitutionListUsers.initData();
			} else if (!this.forUserReplaceModal && this.newTeamSelected === null) {
				this.disableUserAndTeam = false;
				this.$refs.substitutionListTeams.initData();
			}
			this.forUserReplaceModal = true;
		},
		async handleClickBtnSubstitut() {
			const action = async () => {
				const inputForUser = await this.prepareDataToSend('user');
				const inputForTeam = await this.prepareDataToSend('team');
				if (inputForUser.length > 0) {
					await replaceAssignee(inputForUser);
				}
				if (inputForTeam.length > 0) {
					await replaceAssignee(inputForTeam);
				}

				this.initData();

				await this.createToastForMobile('Success', this.FormMSG(8, 'Assignements replaced successfully'), '', 'success', true);
			};

			await this.confirmModal(action, this.FormMSG(9, 'Are you sure ?'));
		},
		async handleListTeamsSelected(payload) {
			this.teamsSelected = payload.teamsSelected;
			this.newTeamSelected = null;

			if (payload.teamsSelected !== null) {
				this.forUserReplaceModal = false;
				this.showReplaceModal = true;
				this.disableUserAndTeam = true;
			}
		},
		async handleListUsersSelected(payload) {
			this.usersSelected = payload.usersSelected;
			this.newUserSelected = null;

			if (payload.usersSelected !== null) {
				this.forUserReplaceModal = true;
				this.showReplaceModal = true;
				this.disableUserAndTeam = true;
			}
		},
		async handleListTaskSelected(task) {
			this.taskSelected = task;
		},
		initData() {
			this.$refs.substitutionListTasks.initData();
			this.$refs.substitutionListTeams.initData();
			this.$refs.substitutionListUsers.initData();
			this.taskSelected = [];
			this.usersSelected = null;
			this.teamsSelected = null;
			this.newUserSelected = null;
			this.newTeamSelected = null;
		},
		prepareDataToSend(type) {
			let dataToSend = [];
			if (type === 'user') {
				if (this.usersSelected !== null) {
					for (let i = 0; i < this.taskSelected.length; i++) {
						const task = this.taskSelected[i];
						dataToSend.push({
							taskId: parseInt(task.id, 10),
							oldAssignedUserId: parseInt(this.usersSelected.id, 10),
							newAssignedUserId: parseInt(this.newUserSelected.id, 10),
							startDate: this.range.start ? moment(this.range.start).format('YYYY-MM-DD') + 'T00:00:00Z' : '',
							endDate: this.range.end ? moment(this.range.end).format('YYYY-MM-DD') + 'T23:59:59Z' : ''
						});
					}
				}
			} else {
				if (this.teamsSelected !== null) {
					for (let i = 0; i < this.taskSelected.length; i++) {
						const task = this.taskSelected[i];
						dataToSend.push({
							taskId: parseInt(task.id, 10),
							oldAssignedTeamId: parseInt(this.teamsSelected.id, 10),
							newAssignedTeamId: parseInt(this.newTeamSelected.id, 10),
							startDate: this.range.start ? moment(this.range.start).format('YYYY-MM-DD') + 'T00:00:00Z' : '',
							endDate: this.range.end ? moment(this.range.end).format('YYYY-MM-DD') + 'T23:59:59Z' : ''
						});
					}
				}
			}

			return dataToSend;
		}
	}
};
</script>

<style scoped>
.alert-info {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
}
</style>
