import { render, staticRenderFns } from "./ListTeams.vue?vue&type=template&id=96a5b642&"
import script from "./ListTeams.vue?vue&type=script&lang=js&"
export * from "./ListTeams.vue?vue&type=script&lang=js&"
import style0 from "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css?vue&type=style&index=0&lang=css&"
import style1 from "./ListTeams.vue?vue&type=style&index=1&id=96a5b642&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tgs-automation/tgs-automation/mojaweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('96a5b642')) {
      api.createRecord('96a5b642', component.options)
    } else {
      api.reload('96a5b642', component.options)
    }
    module.hot.accept("./ListTeams.vue?vue&type=template&id=96a5b642&", function () {
      api.rerender('96a5b642', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AssignationMultiple/ListTeams.vue"
export default component.exports