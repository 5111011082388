<template>
	<div>
		<div>
			<b-form-checkbox
				v-if="isMultipleSelect && tasksCopy.length > 0"
				v-model="isSelectAll"
				:value="true"
				:unchecked-value="false"
				@change="setIsSelectAll"
			>
				{{ FormMSG(1, 'Select All') }}
			</b-form-checkbox>
		</div>
		<perfect-scrollbar :style="{ height: `${$screen.height - 250}px` }">
			<div v-if="tasksCopy.length === 0" class="text-center">
				{{ FormMSG(2, 'No task found') }}
			</div>
			<div v-for="(task, index) in tasksCopy" :key="index">
				<div
					v-if="isMultipleSelect"
					class="list-group-item flex-column align-items-start list-group-item-action mt-2"
					style="border-top: 1px solid rgba(0, 0, 0, 0.125); cursor: pointer; z-index: 0"
					:style="{ backgroundColor: task.isChecked ? '#f3f4f5' : '' }"
				>
					<b-row>
						<b-col>
							<b-form-checkbox v-model="task.isChecked" :value="true" :unchecked-value="false" @change="handleChangeCheckTask($event, task)">
								<strong>{{ task.name }}</strong>
							</b-form-checkbox>
						</b-col>
					</b-row>
				</div>
				<div
					v-else
					class="list-group-item flex-column align-items-start list-group-item-action mt-2"
					style="border-top: 1px solid rgba(0, 0, 0, 0.125); cursor: pointer; z-index: 0"
					:style="{ backgroundColor: task === taskSelected ? '#f3f4f5' : '' }"
					@click="handleClickTask(task)"
				>
					<b-row>
						<b-col>
							<strong>{{ task.name }}</strong>
						</b-col>
					</b-row>
				</div>
			</div>
		</perfect-scrollbar>
	</div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'ListTask',

	components: {
		PerfectScrollbar
	},

	mixins: [languageMessages],

	props: {
		tasks: { type: Array, default: () => [] },
		isMultipleSelect: { type: Boolean, default: false }
	},

	data() {
		return {
			isSelectAll: false,
			taskSelected: [],
			tasksCopy: []
		};
	},

	watch: {
		tasks: {
			handler(val) {
				this.initIsChecked();
				this.tasksCopy = [];
				this.taskSelected = [];
				this.tasksCopy = [...val];
				this.isSelectAll = false;
			},
			immediate: true
		}
	},

	methods: {
		setIsSelectAll(val) {
			this.tasksCopy = this.tasksCopy.map((t) => ({ ...t, isChecked: val }));
			if (val === true) {
				this.taskSelected = this.tasksCopy;
			} else {
				this.taskSelected = [];
			}
			this.emitEvent();
		},
		handleChangeCheckTask(payload, task) {
			if (payload) {
				this.taskSelected.push(task);
			} else {
				this.taskSelected = this.taskSelected.filter((pS) => pS !== task);
			}
			this.checkIsSelectAll();
			this.emitEvent();
		},
		handleClickTask(task) {
			this.taskSelected = task;
			this.emitEvent();
		},
		initData() {
			this.initIsChecked();
			this.taskSelected = [];
		},
		emitEvent() {
			this.$emit('list-task:selected', this.taskSelected);
		},
		checkIsSelectAll() {
			let isSelectAll = true;
			if (this.taskSelected.length !== this.tasksCopy.length) {
				isSelectAll = false;
			}
			this.isSelectAll = isSelectAll;
		},
		initIsChecked() {
			this.tasks.map((lP) => {
				lP.isChecked = false;
			});
		}
	}
};
</script>

<style scoped></style>
