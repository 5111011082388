var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "error-container",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _c(
            "b-card",
            { staticClass: "mt-4", attrs: { "no-body": "" } },
            [
              _c("custom-loading-planning", {
                attrs: {
                  loading: _vm.loading,
                  label: _vm.FormMSG(103, "Preparation list of tasks"),
                },
              }),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "12" } }, [
                        _c(
                          "h1",
                          {
                            class: `${
                              !_vm.$screen.sm ? "main-page-title" : ""
                            }`,
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.FormMSG(1, "Assignments multiples"))
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                        [
                          _c(
                            "fieldset",
                            { staticClass: "scheduler-border my-0 py-0 pb-3" },
                            [
                              _c(
                                "legend",
                                { staticClass: "scheduler-border" },
                                [_vm._v(_vm._s(_vm.FormMSG(2, "Menus")))]
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      class: `${
                                        _vm.$screen.lg ||
                                        _vm.$screen.md ||
                                        _vm.$screen.width < 768
                                          ? "mb-2"
                                          : ""
                                      }`,
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "12",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "btn btn-success w-100",
                                          attrs: {
                                            to: "/plannings",
                                            tag: "button",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-calendar",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t " +
                                              _vm._s(
                                                _vm.FormMSG(3, "Plannings")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "8", xl: "8" } },
                        [
                          _c(
                            "fieldset",
                            { staticClass: "scheduler-border my-0 py-0" },
                            [
                              _c(
                                "legend",
                                { staticClass: "scheduler-border" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(4, "Advanced search"))
                                  ),
                                ]
                              ),
                              _c("filter-planning", {
                                attrs: {
                                  "field-filter": "task",
                                  "filter-for-task": true,
                                  "to-me": false,
                                  "is-not-planning": true,
                                  "only-parent": _vm.chckOnlyParent,
                                  "init-active-view": "day",
                                },
                                on: {
                                  "filters:selected": _vm.handleFiltersSelected,
                                  "new-active-view": _vm.handleNewActiveView,
                                  "today-event": function ($event) {
                                    _vm.$refs.calAssignMultiple.switchView(
                                      "day",
                                      new Date()
                                    )
                                  },
                                  "specific-date:selected":
                                    _vm.handleSpecificDateSelected,
                                  "only-parent:change":
                                    _vm.handleOnlyParentChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "mt-3",
                      attrs: { id: "container-calTasks" },
                    },
                    [
                      _c(
                        "b-col",
                        [
                          _c("vue-cal", {
                            ref: "calAssignMultiple",
                            attrs: {
                              locale: _vm.lang,
                              "hide-body": true,
                              "hide-weekends": true,
                              "selected-date": _vm.selectedDate,
                              "hide-view-selector": "",
                              "hide-week-days": [1, 2, 3, 4, 5, 6, 7],
                              "active-view": _vm.activeView,
                            },
                            on: {
                              "update:activeView": function ($event) {
                                _vm.activeView = $event
                              },
                              "update:active-view": function ($event) {
                                _vm.activeView = $event
                              },
                              "view-change": _vm.handleViewChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tabs",
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: { title: _vm.FormMSG(75, "Assign") },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickTab("assignments")
                            },
                          },
                        },
                        [
                          _c("b-card-text", [
                            _c(
                              "div",
                              [
                                _c("assignments", {
                                  ref: "assignmentsComponent",
                                  attrs: {
                                    tasks: _vm.tasks,
                                    users: _vm.users,
                                    teams: _vm.teams,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          attrs: { title: _vm.FormMSG(76, "Deassign") },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickTab("disassignments")
                            },
                          },
                        },
                        [
                          _c("b-card-text", [
                            _c(
                              "div",
                              [
                                _c("disassignments", {
                                  ref: "disassignmentsComponent",
                                  attrs: {
                                    tasks: _vm.tasks,
                                    users: _vm.users,
                                    teams: _vm.teams,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          attrs: { title: _vm.FormMSG(7, "Replace") },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickTab("substitut")
                            },
                          },
                        },
                        [
                          _c("b-card-text", [
                            _c(
                              "div",
                              [
                                _c("substitution", {
                                  ref: "substitutionComponent",
                                  attrs: {
                                    tasks: _vm.tasks,
                                    users: _vm.users,
                                    teams: _vm.teams,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }