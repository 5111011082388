<template>
	<form>
		<fieldset class="scheduler-border">
			<legend class="scheduler-border">{{ FormMSG(1, 'Users') }}</legend>
			<b-form-group label="Search" horizontal label-cols="3">
				<b-form-input type="text" @input="handleInputSearch" />
			</b-form-group>
			<div v-if="activeCheckbox && !isSingleSelect">
				<b-form-checkbox id="checkbox-user-select-all" v-model="isSelectAll" :value="true" :unchecked-value="false">
					{{ FormMSG(2, 'Select All') }}
				</b-form-checkbox>
			</div>
			<perfect-scrollbar :style="{ height: `${($screen.height - 385) / 2}px` }">
				<div
					v-for="(user, index) in listUsers"
					:key="index"
					class="list-group-item flex-column align-items-start list-group-item-action mt-2"
					style="border-top: 1px solid rgba(0, 0, 0, 0.125); cursor: pointer; z-index: 0"
				>
					<b-row>
						<b-col v-if="activeCheckbox && !isSingleSelect" cols="2">
							<b-form-checkbox
								v-model="user.isAssign"
								:name="`checkbox-${index}`"
								:value="true"
								:unchecked-value="false"
								@change="addAUserSelected($event, user)"
							/>
						</b-col>
						<b-col v-if="activeCheckbox && isSingleSelect" cols="2">
							<b-form-radio
								v-model="singleSelected"
								name="list-users-radio"
								:value="user"
								aria-describedby="ariaDescribedby"
								:disabled="disableUsers"
								@input="handleInputListUsersRadio"
							/>
						</b-col>
						<b-col cols="3">
							<div v-lazy-container="{ selector: 'img' }">
								<img
									v-if="user.picture !== ''"
									:data-src="pathPicture(user.picture)"
									data-error="img/brand/avatar.jpg"
									data-loading="img/brand/tenor.gif"
									:alt="user.picture"
									class="img-circle-custom"
								/>
								<div v-else :style="{ backgroundColor: user.color }" class="not-img">
									<div>{{ getInitial(user.name) }}</div>
								</div>
							</div>
						</b-col>
						<b-col :cols="activeCheckbox ? 7 : 9">
							{{ user.name + ' ' + user.firstName }}
						</b-col>
					</b-row>
				</div>
			</perfect-scrollbar>
		</fieldset>
	</form>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'ListUsers',

	components: {
		PerfectScrollbar
	},

	mixins: [GlobalMixin, languageMessages],

	props: {
		users: { type: Array, default: () => [] },
		task: { type: Object, default: null },
		arrayTask: { type: Array, default: () => [] },
		isSingleSelect: { type: Boolean, default: false },
		singleDataSelected: { type: Object, default: null },
		disableUsers: { type: Boolean, default: false }
	},

	data() {
		return {
			listUsers: [],
			isSelectAll: false,
			usersSelected: [],
			singleSelected: null
		};
	},

	computed: {
		activeCheckbox() {
			if (this.task !== null || this.arrayTask.length > 0) {
				return true;
			} else {
				return false;
			}
		}
	},

	watch: {
		users: {
			handler(val) {
				val.map((user) => {
					user.isAssign = false;
				});
				this.listUsers = val;
			},
			immediate: true
		},
		task: {
			handler(val) {
				this.usersSelected = [];
				this.initIsAssignListUsers();
			},
			immediate: true
		},
		isSelectAll: {
			handler(val) {
				const tmpListUsers = this.listUsers;
				this.listUsers = [];
				tmpListUsers.map((lU) => {
					lU.isAssign = val;
				});
				this.usersSelected = tmpListUsers;
				this.listUsers = tmpListUsers;

				this.emitEvent();
			}
		},
		singleDataSelected: {
			handler(val) {
				this.singleSelected = val;
			},
			immediate: true
		}
	},

	methods: {
		initData() {
			this.isSelectAll = false;
			this.usersSelected = [];
			this.singleSelected = null;
		},
		handleInputListUsersRadio(payload) {
			this.singleSelected = payload;
			this.usersSelected = payload;
			this.emitEvent();
		},
		addAUserSelected(payload, user) {
			if (payload === true) {
				this.usersSelected.push(user);
			} else {
				this.usersSelected = this.usersSelected.filter((uS) => uS !== user);
			}
			this.emitEvent();
		},
		handleInputSearch(payload) {
			this.listUsers = this.searchStringInArray(payload, this.users);
		},
		initIsAssignListUsers() {
			let tmpListUsers = this.listUsers;
			this.listUsers = [];
			tmpListUsers.map((lU) => {
				lU.isAssign = false;
			});
			this.listUsers = tmpListUsers;
		},
		searchStringInArray(str, strArray) {
			let result = [];
			for (let j = 0; j < strArray.length; j++) {
				let pattern = RegExp(str, 'gi');
				if (strArray[j].name.match(pattern) || strArray[j].firstName.match(pattern)) result.push(strArray[j]);
			}
			return result;
		},
		emitEvent() {
			this.$emit('list-users:selected', {
				usersSelected: this.usersSelected
			});
		}
	}
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />

<style lang="scss">
.img-circle-custom {
	border-radius: 50%;
	width: 25px;
	height: 25px;
}
.not-img {
	display: inline-block;
	border-radius: 26px;
	width: 26px;
	height: 26px;
	color: white;
	position: relative;
	> div {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		display: flex;
	}
}
</style>
