<template>
	<error-container :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card class="mt-4" no-body>
				<custom-loading-planning :loading="loading" :label="FormMSG(103, 'Preparation list of tasks')" />
				<b-card-body>
					<b-row>
						<b-col sm="12">
							<h1 :class="`${!$screen.sm ? 'main-page-title' : ''}`">{{ FormMSG(1, 'Assignments multiples') }}</h1>
						</b-col>
					</b-row>
					<b-row class="mb-4">
						<b-col sm="12" md="12" lg="4" xl="4">
							<fieldset class="scheduler-border my-0 py-0 pb-3">
								<legend class="scheduler-border">{{ FormMSG(2, 'Menus') }}</legend>
								<b-row>
									<b-col sm="12" md="12" lg="12" xl="6" :class="`${$screen.lg || $screen.md || $screen.width < 768 ? 'mb-2' : ''}`">
										<router-link to="/plannings" tag="button" class="btn btn-success w-100">
											<i class="fa fa-calendar" aria-hidden="true" />
											&nbsp;{{ FormMSG(3, 'Plannings') }}
										</router-link>
									</b-col>
								</b-row>
							</fieldset>
						</b-col>
						<b-col sm="12" md="12" lg="8" xl="8">
							<fieldset class="scheduler-border my-0 py-0">
								<legend class="scheduler-border">{{ FormMSG(4, 'Advanced search') }}</legend>
								<filter-planning
									:field-filter="'task'"
									:filter-for-task="true"
									:to-me="false"
									:is-not-planning="true"
									:only-parent="chckOnlyParent"
									init-active-view="day"
									@filters:selected="handleFiltersSelected"
									@new-active-view="handleNewActiveView"
									@today-event="$refs.calAssignMultiple.switchView('day', new Date())"
									@specific-date:selected="handleSpecificDateSelected"
									@only-parent:change="handleOnlyParentChange"
								/>
							</fieldset>
						</b-col>
					</b-row>
					<b-row id="container-calTasks" class="mt-3">
						<b-col>
							<vue-cal
								ref="calAssignMultiple"
								:locale="lang"
								:hide-body="true"
								:hide-weekends="true"
								:selected-date="selectedDate"
								hide-view-selector
								:hide-week-days="[1, 2, 3, 4, 5, 6, 7]"
								:active-view.sync="activeView"
								@view-change="handleViewChange"
							/>
						</b-col>
					</b-row>
					<b-tabs>
						<b-tab :title="FormMSG(75, 'Assign')" @click="handleClickTab('assignments')">
							<b-card-text>
								<div>
									<assignments ref="assignmentsComponent" :tasks="tasks" :users="users" :teams="teams" />
								</div>
							</b-card-text>
						</b-tab>
						<b-tab :title="FormMSG(76, 'Deassign')" @click="handleClickTab('disassignments')">
							<b-card-text>
								<div>
									<disassignments ref="disassignmentsComponent" :tasks="tasks" :users="users" :teams="teams" />
								</div>
							</b-card-text>
						</b-tab>
						<b-tab :title="FormMSG(7, 'Replace')" @click="handleClickTab('substitut')">
							<b-card-text>
								<div>
									<substitution ref="substitutionComponent" :tasks="tasks" :users="users" :teams="teams" />
								</div>
							</b-card-text>
						</b-tab>
					</b-tabs>
				</b-card-body>
			</b-card>
		</transition>
	</error-container>
</template>

<script>
import { getTasks } from '@/cruds/task.crud';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import moment from 'moment';
import { getUsers } from '@/cruds/users.crud';
import { getTeams } from '@/cruds/team.crud';
import GlobalMixin from '@/mixins/global.mixin';
import FilterPlanning from '@/components/Plannings/Filter/FilterPlanning';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/fr.js';
import 'vue-cal/dist/i18n/it.js';
import 'vue-cal/dist/i18n/de.js';
import 'vue-cal/dist/i18n/nl.js';
import 'vue-cal/dist/i18n/pt-br.js';
import 'vue-cal/dist/i18n/sk.js';
import Assignments from '@/components/AssignationMultiple/Assignments';
import Disassignments from '@/components/AssignationMultiple/Disassignments';
import Substitution from '@/components/AssignationMultiple/Substitution';
import languageMessages from '@/mixins/languageMessages';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';

export default {
	name: 'Index',

	components: {
		PerfectScrollbar,
		FilterPlanning,
		VueCal,
		Assignments,
		Disassignments,
		Substitution,
		CustomLoadingPlanning
	},

	mixins: [GlobalMixin, languageMessages],

	data() {
		const date = new Date();
		return {
			erreur: {},
			warning: '',
			tasks: [],
			tasksOriginal: [],
			users: [],
			teams: [],
			showUsersAndTeams: false,
			selectedDate: new Date(),
			activeView: 'day',
			filterStartDate: new Date(moment().startOf('week').add(1, 'days')).toISOString(),
			filterEndDate: new Date(moment().endOf('week').add(1, 'days')).toISOString(),
			filterBy: '()',
			chckOnlyParent: false,
			tabActive: 'assignments',
			loading: false,
			cookieName: 'ck_plannings_component_for_plannings',
			cookiesPlannings: {
				selectedDate: new Date(),
				activeView: 'day',
				filterActive: '()',
				startDate: new Date(moment().startOf('week').add(1, 'days')).toISOString(),
				endDate: new Date(moment().endOf('week').add(1, 'days')).toISOString(),
				isMine: false,
				toggleTasks: false,
				chckOnlyParent: false
			}
		};
	},

	async created() {
		await this.getCookiesPlannings();
	},

	mounted() {
		this.$nextTick(async () => {
			await this.reloadData();
		});
		this.checkLanguage();
		this.getUsers();
		this.getTeams();

		this.$nextTick(() => {
			const breadcrumb = document.querySelector('ol.breadcrumb');
			breadcrumb.style.display = 'none';
			breadcrumb.style.marginBottom = '20px';
		});
	},

	destroyed() {
		moment.locale('en');
		this.$nextTick(() => {
			const breadcrumb = document.querySelector('ol.breadcrumb');
			breadcrumb.style.display = 'flex';
			breadcrumb.style.marginBottom = '0';
		});
	},

	methods: {
		getCookiesPlannings() {
			if (this.$cookies.isKey(this.cookieName)) {
				this.cookiesPlannings = this.$cookies.get(this.cookieName);
				this.filterStartDate = new Date(this.cookiesPlannings.startDate).toISOString();
				this.filterEndDate = new Date(this.cookiesPlannings.endDate).toISOString();
				this.filterBy = this.cookiesPlannings.filterActive;
				this.activeView = this.cookiesPlannings.activeView;
				this.selectedDate = new Date(this.cookiesPlannings.selectedDate);
			} else {
				this.$cookies.set(this.cookieName, this.cookiesPlannings, '2d');
			}
		},
		setCookiesPlannings() {
			this.cookiesPlannings.startDate = this.filterStartDate;
			this.cookiesPlannings.endDate = this.filterEndDate;
			this.cookiesPlannings.filterActive = this.filterBy;
			this.cookiesPlannings.activeView = this.activeView;
			this.cookiesPlannings.selectedDate = this.selectedDate;
			this.$cookies.set(this.cookieName, this.cookiesPlannings, '2d');
		},
		async handleClickTab(title) {
			if (this.tabActive !== title) {
				this.tabActive = title;
				await this.initAllComponent();
			}
		},
		async handleOnlyParentChange(payload) {
			this.chckOnlyParent = payload === 'true';
			await this.initAllComponent();
			await this.reloadData();
		},
		async handleSpecificDateSelected(payload) {
			this.filterStartDate = payload.startDate;
			this.filterEndDate = payload.endDate;

			this.selectedDate = new Date(payload.startDate);
			await this.initAllComponent();
			await this.reloadData();
			this.setCookiesPlannings();
		},
		async handleNewActiveView(payload) {
			this.activeView = payload;
			await this.initAllComponent();
			//this.setCookiesPlannings()
		},
		async handleFiltersSelected({ payload, condition }) {
			this.filterBy = condition;
			await this.initAllComponent();
			await this.getTasksWithFilter();
			this.setCookiesPlannings();
		},
		async handleViewChange(payload) {
			this.filterStartDate = moment(payload.startDate).utc();
			this.filterEndDate = moment(payload.endDate).utc();
			await this.initAllComponent();
			await this.reloadData();
			this.setCookiesPlannings();
		},
		async initAllComponent() {
			await this.$refs.assignmentsComponent.initData();
			await this.$refs.disassignmentsComponent.initData();
			await this.$refs.substitutionComponent.initData();
		},
		async reloadData() {
			const taskParentId = this.chckOnlyParent ? 0 : -1;
			const loadMainTask = this.chckOnlyParent === true;
			this.loading = true;

			await getTasks(taskParentId, {
				startDate: this.filterStartDate,
				endDate: this.filterEndDate,
				ofMine: false,
				ofToday: false,
				orderBy: true,
				loadItems: false,
				loadSubTasks: false,
				loadTeams: false,
				loadUsers: false,
				loadTreeView: false,
				loadOffer: false,
				loadDepartment: 0,
				loadTaskByOwner: 0,
				loadMainTask
			}).then((records) => {
				let result = [];
				records.map((task) => {
					if (result.findIndex((r) => task.id === r.id) === -1) {
						result.push({ ...task, isChecked: false });
					}
				});
				this.tasks = result;
				this.tasksOriginal = this.tasks;
				this.getTasksWithFilter();
				this.loading = false;
			});
		},
		getTasksWithFilter() {
			if (this.filterBy !== '()') {
				this.tasks = [];
				for (let i = 0; i < this.tasksOriginal.length; i++) {
					const task = this.tasksOriginal[i];
					if (eval(this.filterBy)) {
						this.tasks.push(task);
					}
				}
			} else {
				this.tasks = this.tasksOriginal;
			}
		},
		async getUsers() {
			this.users = await getUsers({ myProject: true });
		},
		async getTeams() {
			this.teams = await getTeams();
		},
		checkLanguage() {
			moment.locale(this.lang);
		}
	}
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />

<style lang="scss">
#container-calTasks .vuecal__weekdays-headings,
.vuecal__all-day {
	display: none !important;
}
.vuecal__menu,
.vuecal__cell-events-count {
	background-color: #008b8b;
}
.vuecal__title-bar {
	background-color: #e4f5ef;
}
.vuecal__cell--today,
.vuecal__cell--current {
	background-color: rgba(240, 240, 255, 0.4);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
	background-color: rgba(235, 255, 245, 0.4);
}
.vuecal__cell--selected:before {
	border-color: rgba(66, 185, 131, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
	background-color: rgba(195, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
	background-color: rgba(136, 236, 191, 0.25);
}

.vuecal--month-view .vuecal__cell {
	height: 80px;
}

.vuecal--month-view .vuecal__cell-content {
	justify-content: flex-start;
	height: 100%;
	align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
	padding: 4px;
}
.vuecal--month-view .vuecal__no-event {
	display: none;
}
.vuecal__event--dragging {
	background-color: rgba(60, 60, 60, 0.3);
}
.external-event {
	margin-bottom: 0.5em;
	width: 100%;
}
.external-event,
.external-events-drag-and-drop .vuecal__event {
	background-color: rgba(160, 220, 255, 0.5);
	border: 1px solid rgba(0, 100, 150, 0.15);
	padding: 0.2em 0.4em;
	cursor: grab;
}
.vuecal__time-cell-line.hours:before {
	border-color: #42b983;
}
</style>
