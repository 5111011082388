var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        id: "modal-replace",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { hidden: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _vm.forUser
                ? _c("span", [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.FormMSG(21, "Replaced by")) +
                        "\n\t\t"
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.FormMSG(22, "Replaced by")) +
                        "\n\t\t"
                    ),
                  ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        [
          _vm.isForUser
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("list-users", {
                        key: "replace_modal_list_users",
                        ref: "replaceModalListUsers",
                        attrs: {
                          users: _vm.listUsers,
                          "array-task": _vm.taskSelected,
                          "single-data-selected": _vm.dataUserSelected,
                          "is-single-select": "",
                        },
                        on: {
                          "list-users:selected": _vm.handleListUsersSelected,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("list-teams", {
                        key: "replace_modal_list_teams",
                        ref: "replaceModalListTeams",
                        attrs: {
                          teams: _vm.listTeams,
                          "array-task": _vm.taskSelected,
                          "single-data-selected": _vm.dataTeamSelected,
                          "is-single-select": "",
                        },
                        on: {
                          "list-teams:selected": _vm.handleListTeamsSelected,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-button",
                      { staticClass: "mr-2", on: { click: _vm.hideModal } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(3, "Close")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "success",
                          disabled: _vm.enableBtnSaved,
                        },
                        on: { click: _vm.emitEvent },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(4, "Save")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }